export const languageData = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "am",
    name: "Armenian",
    country_code: "am",
  }
];
